<template>

  <div class="container">
    <PrimePreloader v-if="loading"/>
    <div v-else>

      <div class="hr-vacancy-form mt-4">
        <h4 class="text-center">Список преподавателей</h4>

        <div class="col-md-3">
          <div class="input-group mb-3 mt-4">
            <input type="text" class="form-control" placeholder="Найти преподавателя"
                   v-model="inputTeacherName">
            <button class="btn btn-outline-primary" type="button" @click="searchTeacherByName">
              Найти
            </button>
          </div>
        </div>

        <!-- table start -->
        <div class="table-responsive-md mt-4">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">ФИО</th>
              <th scope="col">Итоги анонимного голосования (максимальный)</th>
              <th scope="col">Итоги голосования по критериям (максимальный)</th>
              <th scope="col">Дата</th>
              <th scope="col">Список договоров</th>
              <th scope="col">Создать договор</th>
              <th scope="col" class="text-center"><i class="fas fa-info"></i>
              </th>
              <th scope="col">Принять на работу</th>
            </tr>
            </thead>
            <tbody v-if="kkkUsers.length">
            <tr v-for="(item,index) in kkkUsers" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>
                <button class="btn btn-light" type="button" data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        @click="selectUserId(item.id)">
                  <span style="text-transform: capitalize">{{ getFIO(item) }}</span>

                </button>
              </td>
              <td><b>{{ item.maxAnonymousVotes }}</b></td>
              <td><b>{{ item.maxUserCriterionScores }}</b></td>
              <td>
                {{`${new Date(item.userSyllabus.find(i=> i.kkkInterviewTimes != 0).kkkInterviewTimes.find(i => i.date > 0).date * 1000).toLocaleString()}`}}
                <!--                              {{item.userSyllabus[0].kkkInterviewTimes.length ? new Date(item.userSyllabus.find(i=> i.kkkInterviewTimes != 0).find(i=> i.date)*1000).toLocaleString() : ''}}-->
                <!--                              <div v-for="(userSyllabusInfo,index) in item.userSyllabus" :key="index">-->

                <!--<p>{{item.userSyllabus[index].kkkInterviewTimes.length ? new Date(item.userSyllabus[index].kkkInterviewTimes[0].date*1000).toLocaleString() : ''}}</p>-->

                <!--                              </div>-->

              </td>
              <td>
                <div v-if="item.contracts">
                  <Button label="Список" class="p-button-primary p-mr-2"
                          @click="openContractListModal(item.id)"/>
                </div>
                <div v-else>
                  Договоров нет
                </div>

              </td>
              <td>
                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                        data-bs-target="#agreementModal"
                        @click="openAgreementModal(item.id)">
                  Создать
                </button>
              </td>
              <td>
                <a @click="goToUser(item.id)" class="btn btn-info">
                  <i class="fas fa-info" style="color: #fff"></i>
                </a>
              </td>
              <td>
                <div v-if="+item.active_directory">
                  Принято
                </div>
                <div v-else>
                  <button class="btn btn-success m-1" type="button"
                          @click="addToActiveDirectory(item.id)">
                    Принять
                  </button>
                </div>
<!--                <button class="btn btn-danger m-1" type="button" disabled="disabled">-->
<!--                  Отклонить-->
<!--                </button>-->
              </td>

            </tr>
            </tbody>
          </table>
        </div>
        <!-- table end -->

        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Дисциплины</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div v-if="selectedUserDisciplines.length">
                  <div v-for="(item,index) in selectedUserDisciplines" :key="index">

                    <div class="form-group row mt-4">
                      <label :for="'discipline'+item.disciplineVacancy.discipline.id"
                             class="col-md-9 col-form-label">
                        <b>
                          {{item.disciplineVacancy.discipline.code}}
                        </b>
                        {{item.disciplineVacancy.discipline.name}} -
                        {{item.disciplineVacancy.discipline.language.native_name}}
                        {{ item.kkkInterviewTimes.length ? '( Дата ККК: '+ new Date(+item.kkkInterviewTimes[0].date*1000).toLocaleString()+')' : '' }}
                      </label>
                      <div class="col-md-3"
                           v-if="!item.teacherCourses.some(i => i.discipline_id == item.disciplineVacancy.discipline_id)">
                        <input type="checkbox"
                               :id="'discipline'+item.disciplineVacancy.discipline.id"
                               class="big-checkbox"
                               :value="item.disciplineVacancy.discipline.id"
                               v-model="selectedDisciplines">
                      </div>

                    </div>

                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        @click="postTeacherCourses">Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>


        <!-- Contract Modal-->

        <div class="modal fade" id="agreementModal" tabindex="-1"
             aria-labelledby="agreementModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="agreementModalLabel">Договор</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
              </div>
              <div class="modal-body">

                <div class="col-md-12 mt-4">

                  <div class="form-group row mt-4">
                    <label class="col-md-3 col-form-label">Выберите тип</label>
                    <div class="col-md-9">
                      <select class="form-control form-select"
                              @input="changeContractData('contract_type', $event)">
                        <option v-for="(contractType, contractTypeIndex) in [{name: 'Выберите тип'}, ...contractTypes]"
                                :key="contractTypeIndex"
                                :value="contractType?.id"
                                :selected="contractType?.id==contractData.contract_type">
                          {{contractType.name}}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!--                                    <div class="form-group row mt-4">-->
                  <!--                                        <label for="contract_number" class="col-md-3 col-form-label">-->
                  <!--                                            Номер трудового договора-->
                  <!--                                        </label>-->
                  <!--                                        <div class="col-md-9">-->
                  <!--                                            <input id="contract_number" type="number" class="form-control"-->
                  <!--                                                   @change="changeContractData('contract_number', $event)"-->
                  <!--                                                   :value="contractData.contract_number">-->
                  <!--                                        </div>-->
                  <!--                                    </div>-->

                  <div class="form-group row mt-4">
                    <label for="work_start_date" class="col-md-3 col-form-label">
                      Дата трудового договора
                    </label>
                    <div class="col-md-9">
                      <input id="work_start_date" type="date" class="form-control"
                             @input="changeContractData('work_start_date', $event)"
                             :value="contractData.work_start_date">
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="contract_start_date" class="col-md-3 col-form-label">
                      С даты
                    </label>
                    <div class="col-md-9">
                      <input id="contract_start_date" type="date" class="form-control"
                             @input="changeContractData('contract_start_date', $event)"
                             :value="contractData.contract_start_date">
                    </div>
                  </div>

                  <div class="form-group row mt-4" v-if="contractData.contract_type==1">
                    <label class="col-md-3 col-form-label">Выберите срок</label>
                    <div class="col-md-9">
                      <select class="form-control form-select"
                              @input="changeContractData('contract_term', $event)">
                        <option v-for="(contractTerm, contractTermIndex) in [{name: 'Выберите срок'}, ...contractTerms]"
                                :key="contractTermIndex"
                                :value="contractTerm?.term"
                                :selected="contractTerm?.term==contractData.contract_term">
                          {{contractTerm.name}}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="contract_end_date" class="col-md-3 col-form-label">
                      До даты
                    </label>
                    <div class="col-md-9">
                      <input id="contract_end_date" type="date" class="form-control"
                             @input="changeContractData('contract_end_date', $event)"
                             :value="contractData.contract_end_date">
                    </div>
                  </div>


                  <div class="form-group row mt-4" v-if="contractData.contract_type==1">
                    <label for="additional_contract_status" class="col-md-3 col-form-label">
                      Дополнительное соглашение (штатный)
                    </label>
                    <div class="col-md-9">
                      <input type="checkbox" id="additional_contract_status"
                             class="big-checkbox"
                             :checked="1 == contractData.additional_contract_status"
                             :disabled="contractData.contract_type!=1"
                             @input="changeContractData('additional_contract_status', $event, 'checked')"/>
                    </div>
                  </div>

                  <div class="form-group row mt-4" v-if="contractData.contract_type==2">
                    <label for="additional_contract_status2" class="col-md-3 col-form-label">
                      Дополнительное соглашение (совместитель)
                    </label>
                    <div class="col-md-9">
                      <input type="checkbox" id="additional_contract_status2"
                             class="big-checkbox"
                             :checked="1 == contractData.additional_contract_status2"
                             :disabled="contractData.contract_type!=2"
                             @input="changeContractData('additional_contract_status2', $event, 'checked')"/>
                    </div>
                  </div>

                </div>


              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        @click="postContract">
                  Формировать
                </button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                  Закрыть
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Contract Modal End-->


        <!-- Contract List Modal -->
        <Dialog :header="`Список договоров - ${getFIO(userContracts)}`"
                v-model:visible="displayContractListModal"
                :modal="true"
                :style="{width: '50vw'}">
          <div v-if="userContracts.contracts">
            <div v-for="(contract, contractIndex) in userContracts.contracts"
                 :key="contractIndex"
                 class="mb-2">
              <a :href="`https://back.uib.kz/documents_uploads/${contract?.file_url}`" target="_blank">
                {{contract?.file_url}}
              </a>
            </div>
          </div>
        </Dialog>
        <!-- Contract List Modal End-->

      </div>


    </div>

    <nav v-if="pageCount" class="d-flex justify-content-center" aria-label="Page navigation example">
      <Paginate
          v-model="page"
          :page-count="pageCount"
          :click-handler="paginateHandler"
          :prev-text="'Предыдущий'"
          :next-text="'Следующий'"
          :container-class="'pagination'"
          :page-class="'page-item'">
      </Paginate>
    </nav>

  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import Paginate from '../common/Paginate.vue'


  export default {
    name: 'ActiveDirectory',
    components: {Paginate},

    data() {
      return {
        loading: true,
        page: +this.$route.query.page || 1,
        selectedUserDisciplines: [],
        selectedDisciplines: [],
        inputTeacherName: '',
        contractTypes: [
          {
            id: 1,
            name: 'Штатный'
          },
          {
            id: 2,
            name: 'Совместитель'
          },
          {
            id: 3,
            name: 'Докторантура'
          },
          {
            id: 4,
            name: 'Гкп'
          }
        ],
        contractTerms: [
          {
            term: 1,
            name: '1 год'
          },
          {
            term: 2,
            name: '2 года'
          },
          {
            term: 3,
            name: '3 года'
          }
        ],
        displayContractListModal: false,
        userContracts: {}

      }
    },
    computed: {
      ...mapState('activedirectory', ['kkkUsers', 'pageCount', 'contractData']),
    },
    methods: {
      ...mapActions('activedirectory', ['GET_KKK_USERS', 'POST_ADD_TO_ACTIVE_DIRECTORY', 'POST_CHECKED_DISCIPLINE', 'POST_CONTRACT']),
      ...mapActions('teachingLoad', ['POST_TEACHER_COURSES']),
      ...mapMutations('activedirectory', ['SET_CONTRACT_DATA', 'CLEAR_CONTRACT_DATA']),
      ...mapMutations('userinfo', ['SET_USER_ID']),
      openContractListModal(userId) {
        this.userContracts = this.kkkUsers.find(i => i.id == userId)
        this.displayContractListModal = true
      },
      closeContractListModal() {
        this.displayContractListModal = false
      },
      openAgreementModal(user_id) {
        let additional_contract_status = 0
        let user = this.kkkUsers.find(i => i.id == user_id)
        if (user?.maxAnonymousVotes == 10 && user?.maxUserCriterionScores == 50) {
          additional_contract_status = 1
        }

        this.CLEAR_CONTRACT_DATA({
          user_id,
          additional_contract_status,
          additional_contract_status2: 0,
          contract_type: this.contractTypes[0].id,
          contract_term: this.contractTerms[0].term
        })
      },
      async searchTeacherByName() {
        this.loading = true
        await this.GET_KKK_USERS({
          page: this.page,
          teacher_name: this.inputTeacherName
        })
        this.loading = false
      },
      changeContractData(property, e, val = 'value') {
        let value = e.target[val];
        if (val == 'checked') {
          value = value ? 1 : 0
        }
        this.SET_CONTRACT_DATA({property, value})
      },
      async postContract() {
        const res = await this.POST_CONTRACT()
        if (res.success) {
          await this.GET_KKK_USERS({page: this.page})
          this.$message({text: 'Договор успешно создан'})
          return
        }
        this.$error({title: 'При создании договора произошла ошибка', text: JSON.stringify(res.errors)})
      },
      getFIO(user) {
        return `${user.lastname || ''} ${user.firstname || ''} ${user.middlename || ''}`
      },
      async paginateHandler(page) {
        this.page = page
        await this.changePage()
      },
      async changePage() {
        this.$router.push(`${this.$route.path}?page=${this.page}`)
        await this.GET_KKK_USERS({page: this.page})
      },
      async addToActiveDirectory(user_id) {
        await this.POST_ADD_TO_ACTIVE_DIRECTORY({user_id})
        await this.GET_KKK_USERS({page: this.page})
      },
      async postTeacherCourses() {
        const pps_id = this.selectedUserDisciplines[0].user
        const selectedDisciplines = [...new Set(this.selectedDisciplines)].map(discipline_id => ({
          pps_id,
          discipline_id
        }))

        const {success} = await this.POST_TEACHER_COURSES(selectedDisciplines)
        if (success) {
          this.$message({title: 'Успешно', text: 'Успешно добавлены'})
        } else {
          this.$error({title: 'Ошибка', text: 'Произошла ошибка'})
        }
        await this.GET_KKK_USERS({page: this.page})

      },
      selectUserId(userId) {
        this.selectedDisciplines = []
        this.selectedUserDisciplines = this.kkkUsers.find(i => i.id == userId).userSyllabus

      },
      goToUser(userId) {
        this.SET_USER_ID(userId)
        this.$router.push('/user')
      }

    },
    async mounted() {
      await this.GET_KKK_USERS({page: this.page})
      this.loading = false
    }
  }
</script>
<style scoped>
  .big-checkbox {
    width: 20px;
    height: 20px;
  }
</style>